<template>
  <div class="rooms-summary">

    <p class="rooms-summary__title" v-html="'La tua scelta:'" />

    <div class="rooms-summary__wrap">
      <div class="rooms-summary__item" v-for="(solution_rooms, solution_rooms_index) in solution.rooms" :key="_uid+'_solution_rooms_'+solution_rooms_index">
        <div class="rooms-summary__item-content">
          <span class="rooms-summary__item-qty semibold" v-html="calc_sum(solution_rooms.blocks, 'quantity') + 'x'" />
          <p class="rooms-summary__item-label rooms-summary__item-label--room" v-html="solution_rooms.name" />
        </div>
        <span class="rooms-summary__item-price semibold" v-html="'€ ' + round_up(calc_sum(solution_rooms.blocks, 'live_price'))" />
      </div>
    </div>

    <div class="rooms-summary__wrap rooms-summary__wrap--total">

      <div class="rooms-summary__item">
        <span class="rooms-summary__item-title" v-html="'Costo camere'" />
        <span class="rooms-summary__item-price" v-html="'€ ' + round_up(solution.checkout_payload.net_price)" />
      </div>

      <div class="rooms-summary__item" v-for="(chargeable, chargeable_index) in solution.checkout_payload.extra_charge.chargeable" :key="_uid+'_chargeable_'+chargeable_index">
        <div class="rooms-summary__item-content">
          <span class="rooms-summary__item-title" v-html="get_extra_charge(chargeable.name).name" />
          <vb-icon v-if="get_extra_charge(chargeable.name).tooltip !== ''" :name="'info'" :color="'adb5bd'" :size="13" class="ml-1" v-b-popover.hover.top="get_extra_charge(chargeable.name).tooltip" />
        </div>
        <span class="rooms-summary__item-price" v-html="'€ ' + round_up(chargeable.amount)" />
      </div>

      <div class="rooms-summary__item">
        <div class="rooms-summary__item-content">
          <span class="rooms-summary__costs-title" v-html="'Gestione e assistenza'" />
          <vb-icon :name="'info'" :color="'adb5bd'" :size="13" class="ml-1" v-b-popover.hover.top="'Questi costi includono tasse e costi di gestione della prenotazione, oltre alla nostra assistenza clienti sempre a portata di click.'" />
        </div>
        <span class="rooms-summary__costs-price" v-html="'€ ' + markup(solution.checkout_payload.charge_price).markup" />
      </div>

      <div class="rooms-summary__item rooms-summary__item--total">
        <span class="rooms-summary__item-title semibold" v-html="'Totale'" />
        <span class="rooms-summary__item-price semibold" v-html="'€ ' + round_up(solution.checkout_payload.live_price)" />
      </div>

      <credit-alert
        v-if="activate_credit_alert && get_availability(solution.checkout_payload.live_price).show_credit_alert"
        :color="'danger'"
        class="rooms-summary__credit"
      />
    </div>

    <p class="rooms-summary__title" v-if="Object.keys(solution.checkout_payload.extra_charge.not_chargeable).length" v-html="'Da pagare in struttura:'" />

    <div class="rooms-summary__item" v-for="(not_chargeable, not_chargeable_index) in solution.checkout_payload.extra_charge.not_chargeable" :key="_uid+'_not_chargeable_'+not_chargeable_index">
      <div class="rooms-summary__item-content">
        <span class="rooms-summary__item-title" v-html="get_extra_charge(not_chargeable.name).name" />
        <vb-icon v-if="get_extra_charge(not_chargeable.name).tooltip !== ''" :name="'info'" :color="'adb5bd'" :size="13" class="ml-1" v-b-popover.hover.top="get_extra_charge(not_chargeable.name).tooltip" />
      </div>
      <span class="rooms-summary__item-price" v-html="'€ ' + round_up(not_chargeable.amount)" />
    </div>

  </div>
</template>

<script>
import {number} from "mathjs"
import {markup, round_up} from '@booking/utilities.js'
import {get_extra_charge} from '@booking/resources'
import CreditAlert from "@components/atoms/credit-alert.vue";


export default {
  name: 'rooms-summary',

  components: {
    'credit-alert': CreditAlert,
  },

  props: {
    solution: Object,
    activate_credit_alert: {
      type: Boolean,
      default: true
    },
  },

  data() {
    return {
    }
  },

  computed: {
  },

  created() {
    this.markup = markup
    this.get_extra_charge = get_extra_charge
  },

  mounted() {
  },

  methods: {
    round_up,

    calc_sum(blocks, keyword) {
      let sum = 0;
      for (const block_key in blocks) {
        sum += number(blocks[block_key].incremental_price_selected[keyword])
      }
      return sum;
    },
  },
}
</script>

<style lang="scss" scoped>

  .rooms-summary {

    &__title {
      font-weight: $vb-font-weight-semibold;
    }

    &__wrap {
      padding-bottom: 5px;
      border-bottom: 1px solid $gray-400;
      margin-bottom: 5px;

      &:last-child, &--total {
        border-bottom: 0;
        margin-bottom: 0;
        padding-bottom: 0;
      }
      &--total {
        margin-bottom: $grid-gutter-width;
      }
    }

    &__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: $font-size-md;
      margin-bottom: 2px;

      &:last-child {
        margin-bottom: 0;
      }

      &-content {
        display: flex;
        align-items: center;
        overflow: hidden;
        flex-basis: 80%;
      }

      &-label {

        &--room {
          margin-bottom: 0;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-left: 10px;
        }
      }

      &--total {
        padding-top: 10px;
        margin-top: 10px;
        border-top: 1px solid $gray-400;
      }
    }

    &__credit {
      display: flex;
      justify-content: flex-end;
    }
  }

</style>