<template>
  <div class="key-info" v-if="hotel.key_collection_info">
    <div class="key-info__wrap">
      <p class="key-info__title" v-html="'Recupero della chiavi'" />
      <span v-if="hotel.key_collection_info.how_to_collect" class="key-info__item" v-html="'Come raccogliere le chiavi: ' + get_key_collection(hotel.key_collection_info.how_to_collect)" />
      <span v-if="hotel.key_collection_info.key_location" class="key-info__item" v-html="'Posizione delle chiavi: ' + get_key_collection(hotel.key_collection_info.key_location)" />

      <p v-if="hotel.key_collection_info.alternative_key_location && Object.keys(hotel.key_collection_info.alternative_key_location).length" class="key-info__item">
        {{"Chiave alternativa situata in:"}}
        <template v-for="(location, location_index) in hotel.key_collection_info.alternative_key_location">
           <span :key="_uid+'_location_'+location_index" v-html="location + ' '" />
        </template>
      </p>

      <span class="key-info__item" v-if="hotel.key_collection_info.other_text" v-html="hotel.key_collection_info.other_text" />
    </div>
  </div>
</template>

<script>
import {get_key_collection} from "@booking/resources";

export default {
  name: 'info',

  components: {
  },

  props: {
    hotel: Object
  },

  data() {
    return {
    }
  },

  computed: {
  },

  created() {
    this.get_key_collection = get_key_collection
  },

  mounted() {
  },

  methods: {
  },
}
</script>

<style lang="scss" scoped>

  .key-info {

    &__title {
      font-weight: $vb-font-weight-semibold;
      margin-bottom: 10px;
    }

    &__item {
      display: block;
      margin-top: 5px;

      &:first-child {
        margin-top: 0;
      }
    }
  }

</style>